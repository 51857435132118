import React, { useEffect, useState } from "react";
import play from "../../assets/img/playstore.png";
import apple from "../../assets/img/apple.png";
import mobile from "../../assets/img/mobile.png";
import logo from "../../assets/img/logo.png";
import security from "../../assets/img/security.png";
import payment from "../../assets/img/payment.png";
import earth from "../../assets/img/earth.png";
import userOne from "../../assets/img/userFirst.png";
import userTwo from "../../assets/img/userSec.png";
import userThree from "../../assets/img/userThree.png";

export default function Dashboard() {
  const [plan, setPlan] = useState([])

  const [active, setActive] = useState(0)

  const getPlan = async () => {
    fetch('https://gapshap-admin.onrender.com/api/plan/get-all-plan').then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(data => {
      setPlan(data.result)
      console.log(data);
    }).catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
  }


  useEffect(() => {
    getPlan()
  }, [])

  // console.log("plan: ", plan);
  return (
    <>
      <div className="bg-[#6D53F5]">
        <div className="container mx-auto lg:w-[1600px] w-full p-2">
          <div className="flex justify-center  ">
            <div className="w-11/12 p-2 rounded-md py-3 mt-5 mb-5 bg-white relative flex justify-between">
              <div className="flex items-center gap-3">
                <img className="h-10 w-10" src={logo} />
                <div>Dating</div>
              </div>
              <div className="bg-[#6D53F5] w-40  rounded-2xl text-white p-3  text-center">
                Get the App
              </div>
            </div>
          </div>
          <div className="text-white text-[14px] mt-14 lg:ml-14 ">
            -Find Your Match Now!
          </div>
          <div className="lg:ml-14">
            <div className="flex items-center">
              <div className="text-[24px] text-white">Find </div>
              <div className="text-[#FF7C9F] text-[24px]">
                Your Soulmate with{" "}
              </div>
            </div>
            <div className="text-[24px] text-white">Our Dating App</div>
          </div>
          <div className=" grid lg:grid-cols-2 grid-cols-1 mt-10 lg:ml-14   ">
            <div className="flex justify-end">
              <div>
                <div className="text-white mt-10 w-1/2 ">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been
                </div>
                <div className="flex items-center gap-10 mt-10">
                  <div className="bg-black rounded-md p-4">
                    <div className="flex items-center gap-5">
                      <img className="h-10 w-10" src={play} />
                      <div>
                        <div className="text-white text-[10px]">GET IT ON</div>
                        <div className="text-white text-[10px]">
                          Google Play
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-black rounded-md p-4">
                    <div className="flex items-center gap-5">
                      <img className="h-10 w-10" src={apple} />
                      <div>
                        <div className="text-white text-[10px]">GET IT ON</div>
                        <div className="text-white text-[10px]">
                          Google Play
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img className="w-8/12" src={mobile} />
            </div>
          </div>
          <div className="mt-10 flex items-center py-4">
            <img className="w-24 h-24" src={userOne} />
            <img className="w-24 h-24 -ml-14" src={userTwo} />
            <img className="w-24 h-24  -ml-16" src={userThree} />
            <div className="ml-10">
              <div className="text-white text-xl font-bold">5M +</div>
              <div className="text-white text-xl font-bold">Active User</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="text-center text-[15px]">
          Benefits of our Dating App
        </div>
        <div className="flex items-center justify-center gap-2 ">
          <div className="text-2xl">Our</div>
          <div className="text-2xl text-[#6D53F5] ">Dating App</div>
          <div className="text-2xl">Benefits</div>
        </div>
      </div>
      <div className="container mx-auto mt-24">
        <div className="grid lg:grid-cols-3 grid-cols-2 mt-10 justify-center p-2 gap-4">
          <div>
            <div className="bg-slate-200 w-2/12 rounded-md p-2">
              <img className="" src={security} />
            </div>
            <div className="text-lg">Privacy</div>
            <div className="w-10/12 mt-6">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </div>
          </div>
          <div>
            <div className="bg-slate-200 w-2/12 rounded-md p-2 ">
              <img className="" src={payment} />
            </div>
            <div className="text-lg">Secure Payments</div>
            <div className="w-10/12 mt-6">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </div>
          </div>
          <div>
            <div className="bg-slate-200 w-2/12 rounded-md p-2">
              <img className="" src={earth} />
            </div>
            <div className="text-lg">24 x 7 Support</div>
            <div className="w-10/12 mt-6">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </div>
          </div>
        </div>
        <div className=" text-center text-xl text-[#6D53F5] mt-24">
          Pricing and Plans
        </div>
        <div className="mt-2 text-center font-bold text-3xl ">
          Let’s Know the Pricing{" "}
        </div>
        <div className="mt-2 font-bold text-center text-3xl text-[#6D53F5]">
          Plan for You
        </div>

        {/* this is data fetching */}
        <div className="grid lg:grid-cols-3 grid-cols-1 mt-20 gap-4 " >
        {plan.map((item, ind) => {
          return (
              <div className="shadow-2xl border p-4 " key={ind}>
                <div className="flex items-center  ">
                  <div>${item?.price}</div>
                  <div >/Month</div>
                </div>
                <div >{item?.type}</div>
                <div className="mt-4 text-slate-600 mb-4 ">{item?.title}</div>
                <div className="flex gap-5 mt-2 ">

                  <svg className="w-8 h-8 text-[#6D53F5] " fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>

                  <div >{item?.description1}</div>
                </div>

                <div className="flex gap-5 mt-2 ">
                  <svg className="w-8 h-8" fill="#6D53F5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  <div>{item?.description2}</div>
                </div>

                <div className="flex gap-5 mt-2">
                  <svg className="w-8 h-8" fill="#6D53F5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  <div>{item?.description3}</div>
                </div>

                <div className="flex gap-5 mt-2">
                  <svg className="w-8 h-8" fill="#6D53F5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  <div>{item?.description4} </div>
                </div>

                <div className="flex gap-5 mt-2">
                  <svg className="w-8 h-8" fill="#6D53F5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  <div>{item?.description5}</div>
                </div>

                {item?.description6 ?
                  <div className="flex gap-5 mt-2">
                    <svg className="w-8 h-8" fill="#6D53F5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                    <div>{item?.description6}</div>
                  </div>
                  : ""
                }

                {item?.description7 ?
                  <div className="flex gap-5 mt-2">
                    <svg className="w-8 h-8" fill="#6D53F5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                    <div>{item?.description7}</div>
                  </div>
                  : ""
                }

                {item?.description8 ?
                  <div className="flex gap-5 mt-2">
                    <svg className="w-8 h-8" fill="#6D53F5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                    <div>{item?.description8}</div>
                  </div>
                  : ""
                }

                {item?.description9 ?
                  <div className="flex gap-5 mt-2">
                    <svg className="w-8 h-8" fill="#6D53F5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                    <div>{item?.description9}</div>
                  </div>
                  : ""
                }

                {item?.description10 ?
                  <div className="flex gap-5 mt-2">
                    <svg className="w-8 h-8" fill="#6D53F5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                    <div>{item?.description10}</div>
                  </div>
                  : ""
                }
                <div className="flex justify-center py-4">
                  <button className="border rounded-lg p-4 bg-[#6D53F5] text-white w-6/12 ">Choose Plan</button>
                </div>
              </div>
          )
        })}
            </div>

      </div>
      <div className="bg-[#E9E3E3] mt-4">
        <div className="grid lg:grid-cols-3  grid-cols-1 container mx-auto p-1 ">
          <div className="p-4">
            <div className="flex gap-3 items-center">
              <img className="w-24 h-24" src={logo} />
              <div className="font-bold text-2xl">Dating App</div>
            </div>
            <div className="text-sm py-6">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.Lorem Ipsum is simply dummy text of the printing and
              typesetting industry.
            </div>
            <div className="flex items-center mt-8 gap-6">
              <svg
                className="w-10 h-10"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
              </svg>
              <svg
                className="w-10 h-10"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
              <svg
                className="w-10 h-10"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </div>
          </div>

          <div className="mt-10">
            <div className="font-bold text-2xl">Get the App</div>
            <div className=" gap-10 mt-10">
              <div className="bg-black rounded-md p-4 mb-6 w-6/12">
                <div className="flex items-center gap-5">
                  <img className="h-10 w-10" src={play} />
                  <div>
                    <div className="text-white text-[10px]">GET IT ON</div>
                    <div className="text-white text-[10px]">Google Play</div>
                  </div>
                </div>
              </div>
              <div className="bg-black rounded-md p-4 w-6/12">
                <div className="flex items-center gap-5  ">
                  <img className="h-10 w-10" src={apple} />
                  <div>
                    <div className="text-white text-[10px]">GET IT ON</div>
                    <div className="text-white text-[10px]">Google Play</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="font-bold text-2xl mb-6 ">Contact Us</div>
            <div className="flex items-center gap-4 py-2">
              <svg
                fill="#2D3E7F"
                className="w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
              </svg>
              <div>123-456-789</div>
            </div>
            <div className="flex items-center gap-4 py-2">
              <svg
                fill="#2D3E7F"
                className="w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
              </svg>
              <div>Loren Ispum 123,Loren Ispum 12345</div>
            </div>
            <div className="flex items-center gap-4 py-2">
              <svg
                fill="#2D3E7F"
                className="w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" />
              </svg>
              <div>example123@gmail.com</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
